import React from 'react';
import SVG from 'react-inlinesvg';
import waves from '../svg/waves.svg';
import {Fade} from 'react-slideshow-image';
import axios from 'axios';


export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            img_list: ['1.jpg']
        }
    }

    getImageList = async () => {
        let url = '/img.php?m=slider';
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {  // Для редактирования сайта
            url = "https://santidom.ru" + url;
        }
        try {
            const imageList = await axios.get(url, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                },
            });
            this.setState({img_list: imageList.data}); 
        } catch (error) {
            
        }
        console.log(this.state.img_list); 
    }

    componentDidMount() {
        this.getImageList();
    }
    render() {
        const fadeProperties = {
            duration: 5000,
            transitionDuration: 500,
            infinite: true,
            indicators: false,
            arrows: false
        };
        const img_list = this.state.img_list;
        console.log(img_list);
        return (
            <header>
                <div className="slide-container">
                    <Fade {...fadeProperties}>
                        {img_list.map(v => (
                            <div className="image-container" style={{backgroundImage: `url(/images/slider/${v})`}}>
                                <div className="image-container--blur" />
                            </div>
                        ))}
                    </Fade>
                </div>
                <div className="header-main">
                    <div className="logo">
                        <img src="/images/logo_big.png" alt="Логотип"/>
                    </div>
                    <SVG src={waves} />
                </div>
            </header>
        );
    }
}