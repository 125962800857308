import React from 'react';
import SVG from 'react-inlinesvg';
import waves from '../svg/waves.svg';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/scss/image-gallery.scss";


export default class LargeGallerySection extends React.Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }
    render() {
        const images = Array.from(Array(107), (e,i)=>i+1).map((v) => {
            return {
                original: `/images/work/full/${v}.jpg`,
                thumbnail: `/images/work/thumbnails/${v}_tn.jpg`
            }
        });
        return (
            <section className="large-gallery">
                <div className="container large-gallery--content">
                    <h3>Наши работы</h3>
                    <ImageGallery items={images} />
                </div>
                <SVG src={waves} className="svg-divider dark" />
            </section>
        );
    }
}