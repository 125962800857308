import React from 'react';
import Slider from 'react-slick';
import {isMobile} from 'react-device-detect';
import axios from 'axios';

export default class TeamSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            img_list: ['1.jpg', '2.jpg', '3.jpg', '4.jpg']
        }
    }

    getImageList = async () => {
        let url = "/img.php?m=team";
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {  // Для редактирования сайта
            url = "https://santidom.ru" + url;
        }
        try {
            const imageList = await axios.get(url, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                },
            });
            this.setState({img_list: imageList.data}); 
        } catch (error) {
            
        }
        console.log(this.state.img_list); 
    }

    componentDidMount() {
        this.getImageList();
    }
    render() {
        return (
            <section className="team">
                <div className="container team--content">
                    <h3>Наша команда</h3>
                    <Slider
                        dots={true}
                        infinite={true}
                        speed={500}
                        slidesToShow={isMobile ? 1 : 3}
                        slidesToScroll={1}
                        autoplay={true}
                        autoplaySpeed={3000}
                        centerMode={false}
                        >
                        {this.state.img_list.map(v => (<img src={`/images/team/${v}`} alt={`Фото ${v}`} />))}
                    </Slider>
                </div>
            </section>
        );
    }
}