import React from 'react';
import axios from 'axios';
import toast from 'toasted-notes';

export default class ContactSection extends React.Component {

    sendContactForm = () => {
        const name = document.getElementById('contact--content--form--name').value;
        const phone = document.getElementById('contact--content--form--phone').value;
        const email = document.getElementById('contact--content--form--email').value;
        const message = document.getElementById('contact--content--form--text').value;
        axios.post('/mailer.php?f=2', {
            name: name,
            ph: phone,
            email: email,
            message: message
          })
          .then(function (response) {
            const msg = response.data.data.message;
            console.log(msg);
            toast.notify(msg);
          })
          .catch(function (error) {
            toast.notify('Извините, возникли технические неполадки. Данная функция пока недоступна.');
          });
    }

    render() {
        return (
            <section className="contact">
                {/* eslint-disable-next-line */}
                <a id="contacts" />
                <div className="container contact--content">
                    <h3>Свяжитесь с нами!</h3>
                    <div className="contact--content--row">
                        <div className="contact--content--col contact-form">
                            <div className="contact--content--form">
                                <input type="text" id="contact--content--form--name" placeholder="Ваше имя"/>
                                <input type="tel" id="contact--content--form--phone" placeholder="Номер телефона"/>
                                <input type="email" id="contact--content--form--email" placeholder="Адрес электронной почты"/>
                                <textarea id="contact--content--form--text" placeholder="Ваше сообщение"/>
                            </div>
                            <div className="btn-wrapper">
                                {/* eslint-disable-next-line */}
                                <a className="btn" onClick={this.sendContactForm}>Отправить</a>
                            </div>
                        </div>
                        <div className="contact--content--col contact-icons">
                            <i className="fas fa-toilet" />
                            <i className="fas fa-shower" />
                            <i className="fas fa-tools" />
                            <i className="fas fa-bath" />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}