import React from 'react';
import Slider from "react-slick";
import {isMobile} from 'react-device-detect';
import ReactBnbGallery from 'react-bnb-gallery';
import 'react-bnb-gallery/dist/style.css';
import axios from 'axios';


export default class GallerySection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            gallery_opened: false,
            img_list: {
                "full": ['1.jpg', '2.jpg', '3.jpg', '4.jpg'],
                "thumbnails": ['1.jpg', '2.jpg', '3.jpg', '4.jpg'],
                "main": ['1.jpg', '2.jpg', '3.jpg', '4.jpg']
            }
        };
    }

    getImageList = async () => {
        let url = "/img.php?m=work"
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {  // Для редактирования сайта
            url = "https://santidom.ru" + url;
        }
        console.log(url);
        try {
            
            const imageList = await axios.get(url, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                },
            });
            this.setState({img_list: imageList.data}); 
        } catch (error) {
            
        }
        console.log(this.state.img_list); 
    }

    componentDidMount() {
        this.getImageList();
    }

    render() {
        return (
            <section className="gallery">
                <div className="container gallery--content">
                    <h3>Наши работы</h3>
                    <Slider
                        dots={true}
                        infinite={true}
                        speed={500}
                        slidesToShow={isMobile ? 1 : 3}
                        slidesToScroll={1}
                        autoplay={false}
                        autoplaySpeed={3000}
                        centerMode={false}
                        >
                        {this.state.img_list.main.map(v => (<img src={`/images/work/${v}`} alt={`Фото ${v}`} />))}
                    </Slider>
                    <div className="btn-wrapper">
                        <button className="btn" onClick={this.toggleGallery}>Больше фотографий</button>
                    </div>
                    <ReactBnbGallery
                        show={this.state.gallery_opened}
                        photos={Array.from(Array(this.state.img_list.full.length), (e,i)=>i+1).map((v) => {
                            return {
                                photo: `/images/work/full/${v}.jpg`,
                                thumbnail: `/images/work/thumbnails/${v}_tn.jpg`
                            }
                        })}
                        onClose={this.toggleGallery}
                        phrases={{
                            noPhotosProvided: "Здесь пока нет фотографий",
                            showPhotoList: "Показать список фотографий",
                            hidePhotoList: "Скрыть список фотографий"
                        }}
                        /> 
                </div>
            </section>
        );
    }

    toggleGallery = () => {
        this.setState(prevState => ({gallery_opened: !prevState.gallery_opened}));
    }
}