import React from 'react';
import { HashRouter as Router, Route } from "react-router-dom";
import { jarallax } from 'jarallax';
import 'toasted-notes/src/styles.css';

import Navigation from './components/navigation';
import Header from './components/header';
import BrandCloudSection from './components/brand_cloud';
import TimeAndPlaceSection from './components/timeandplace';
import DeliverySection from './components/delivery';
import GallerySection from './components/gallery';
import AboutSection from './components/about';
import ContactSection from './components/contact';
import FooterSection from './components/footer';
import LargeGallerySection from './components/large_gallery';
import './index.scss'; 
import TeamSection from './components/team';

export default class App extends React.Component {
  componentDidMount() {
    jarallax(document.querySelectorAll('.jarallax'), {
      speed: 0.5
    });
  }

  fadeOutEffect = (target, speed) => {
    const fadeEffect = setInterval(function () {
        if (!target.style.opacity) {
            target.style.opacity = 1;
        }
        if (target.style.opacity > 0) {
            target.style.opacity -= 0.1;
        } else {
            clearInterval(fadeEffect);
        }
    }, speed);
  }

  fadeInEffect = (target, speed) => {
    const fadeEffect = setInterval(function () {
        console.log(target.style.opacity);
        if (!target.style.opacity) {
            target.style.opacity = 0;
        }
        if (target.style.opacity < 1.0) {
            target.style.opacity = target.style.opacity + 0.1;
        } else {
            clearInterval(fadeEffect);
        }
    }, speed);
  }

  isVisible = el => {
    const scroll = window.scrollY || window.pageYOffset
    const boundsTop = el.getBoundingClientRect().top + scroll
    
    const viewport = {
      top: scroll,
      bottom: scroll + window.innerHeight,
    }
    
      const bounds = {
      top: boundsTop,
      bottom: boundsTop + el.clientHeight,
    }
    
    return ( bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom ) 
      || ( bounds.top <= viewport.bottom && bounds.top >= viewport.top );
  }

  render(){
    return (
      <div className="App">
        <Router>
          {/* eslint-disable-next-line */}
          <a id="home" />
          <Navigation />
          <Route exact path="/">
            <Header />
            <main>
              {/*Форма обратного звонка и время работы*/}
              <TimeAndPlaceSection />
              {/* eslint-disable-next-line */}
              <a id="catalog" />
              {/* Слайдер с брендами */}
              <BrandCloudSection />
              {/* eslint-disable-next-line */}
              <a id="work" />
              {/* Галерея с работами*/}
              <GallerySection />
              {/* eslint-disable-next-line */}
              <a id="about" />
              {/* О нас */}
              <AboutSection />
              {/* eslint-disable-next-line */}
              <a id="contact" />
              {/* Блок "Свяжитесь с нами" */}
              <ContactSection />
              {/* eslint-disable-next-line */}
              <a id="delivery" />
              {/* Блок "Доставка и оплата" */}
              <DeliverySection />
              {/* eslint-disable-next-line */}
              <a id="team" />
              {/* Блок "Наша команда" */}
              <TeamSection />
              {/* eslint-disable-next-line */}
              <a id="footer" />
              {/* Подвал */}
              <FooterSection />
            </main>
          </Route>
          <Route path="/gallery">
            <main>
              <LargeGallerySection />
              <FooterSection />
            </main>
          </Route>
        </Router>
      </div>
    );
  }
}
