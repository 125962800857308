import React from 'react';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import SVG from 'react-inlinesvg';
import waves from '../svg/waves.svg';

export default class FooterSection extends React.Component {
    componentDidMount() {
        tippy('[data-tippy-content]');
    }

    render() {
        return (
            <footer className="jarallax">
                <picture className="jarallax-img">    
                    <img src="/images/parallax-footer.jpg" alt="" />
                </picture>
                <div className="parallax-blur" />
                <SVG src={waves} className="svg-divider red reverse top"/>
                <div className="container footer--content">
                    <div className="footer--content--map">
                        <iframe id="gmap_canvas" data-tippy-content="Мы на карте" title="Мы на карте" src="https://maps.google.com/maps?q=%D0%A1%D1%82%D1%80%D0%BE%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%80%D1%8B%D0%BD%D0%BE%D0%BA%20%D0%AF%D0%B3%D0%BE%D0%B4%D0%BA%D0%B0&language=ru&z=15&output=embed" frameborder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                    </div>
                    <div className="footer--content--contacts">
                        <h3>Контакты</h3>
                        <ul>
                            <li><a href="tel:+74993933037" data-tippy-content="Нажмите, чтобы позвонить">
                                <i className="fas fa-phone" data-tippy-content="Позвоните нам!"/> 
                                <i className="fab fa-whatsapp" data-tippy-content="WhatsApp"/> 
                                 +7 (499) 393-30-37
                            </a></li>
                            <li><a href="tel:+79067737245" data-tippy-content="Нажмите, чтобы позвонить">
                                <i className="fas fa-phone" data-tippy-content="Позвоните нам!"/> 
                                <i className="fab fa-whatsapp" data-tippy-content="WhatsApp"/> 
                                <i className="fab fa-viber" data-tippy-content="Viber"/> 
                                +7 (906) 773-72-45
                            </a></li>
                            <li><a href="mailto:info@santidom.ru" data-tippy-content="Нажмите, чтобы написать сообщение"><i className="fas fa-envelope" /> info@santidom.ru</a></li>
                        </ul>
                        <ul>
                            <li><a data-tippy-content="Нажмите, чтобы проложить маршрут в Google Картах" rel="noopener noreferrer" target="_blank" href="https://www.google.com/maps/dir//55.6605712,37.0908472/@55.660571,37.090847,15z?hl=ru-RU"><i className="fas fa-map-marker-alt" /> Одинцовский р-он, 2-ое Успенское шоссе, д.42. к.1, «Ягодка Village»</a></li>
                            <li><i className="fas fa-clock"/> 8:00-20:00, без выходных</li>
                        </ul>
                    </div>
                </div>
                <div className="copyright">
                    <div className="container copyright--container">
                        <span>(c) Магазин сантехники "Сантидом", 2020</span>
                        <span className="copyright--container--author">Сделано <a target="_blank" rel="noopener noreferrer" href="https://gtwenty.com/">G Twenty</a></span>
                    </div>
                </div>
            </footer>
        );
    }
}