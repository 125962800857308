import React from 'react';
import SVG from 'react-inlinesvg';
import waves from '../svg/waves.svg';
import ReactBnbGallery from 'react-bnb-gallery';
import 'react-bnb-gallery/dist/style.css';


export default class AboutSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            gallery_opened: false,
        }
    }

    render() {
        return (
            <section className="about jarallax">
                <picture className="jarallax-img">    
                    <img src="/images/parallax-about.jpg" alt="" />
                </picture>
                <div className="parallax-blur" />
                <SVG src={waves} className="svg-divider reverse-inverse red top"/>
                <div className="container about--content">
                    <h3>О нас</h3>
                    <p>
                        Магазин «Санти» уже более 10 лет осуществляет торговлю сантехникой, а так же предоставляет услуги по монтажу и обслуживанию инженерных коммуникаций. <br />
                        За все время существования, даже в сложные периоды становления, магазин "Санти" зарекомендовал себя как надежный партнер не только для клиентов, но и для поставщиков. <br />
                        В основе нашей работы лежит взаимовыгодное, долгосрочное сотрудничество с ориентацией на пожелания клиента. Конкурентными преимуществами магазина «Санти» являются наличие широкого выбора ассортимента в магазине. <br />
                        Постоянное пополнение и наличие товара на складе, быстрая обработка и полное соблюдение комплектации заказа, сохранение доступных цен. С нами можно решить многие задачи: от обустройства объекта бюджетной продукцией до обустройства продукцией премиум класса.<br />
                        В случае возникновения спорных моментов и вопросов, связанных с рекламацией (гарантией), мы гарантированно решим вопрос и сделаем это в пользу клиента.
                    </p>
                    <p>
                        Мы ценим своих покупателей и их время!
                    </p>
                    <div className="btn-wrapper">
                        {/*eslint-disable-next-line*/}
                        <a className="btn" onClick={() => {this.setState({gallery_opened: !this.state.gallery_opened})}}>Сертификаты</a>
                    </div>
                    <ReactBnbGallery
                        show={this.state.gallery_opened}
                        photos={[1,2].map((v) => {
                            return {
                                photo: `/images/certificates/${v}.jpg`,
                                thumbnail: `/images/certificates/${v}_tn.jpg`
                            }
                        })}
                        onClose={() => {this.setState({gallery_opened: !this.state.gallery_opened})}}
                        phrases={{
                            noPhotosProvided: "Здесь пока нет фотографий",
                            showPhotoList: "Показать список фотографий",
                            hidePhotoList: "Скрыть список фотографий"
                        }}
                        /> 
                </div>
                <SVG src={waves} className="svg-divider"/>
            </section>
        );
    }
}