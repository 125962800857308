import React from 'react';
import axios from 'axios';
import toast from 'toasted-notes';

export default class TimeAndPlaceSection extends React.Component {

    sendCallbackForm = () => {
        const phone = document.getElementById('timeandplace--phone').value;
        axios.post('/mailer.php?f=1', {
            ph: phone
          })
          .then(function (response) {
            console.log(response);
            const msg = response.data.data.message;
            console.log(msg);
            toast.notify(msg);
          })
          .catch(function (error) {
            toast.notify('Извините, возникли технические неполадки. Данная функция пока недоступна.');
          });
    }

    render() {

        return (
            <section className="timeandplace">
                <div className="container">
                    <div className="timeandplace--content" id="timeandplace-content">
                        <div className="timeandplace--content--col">
                            <p><i className="far fa-clock" /> 8:00-20:00, без выходных</p>
                            <p><i className="fas fa-map-marker-alt" /> Одинцовский район, 2-ое Успенское шоссе, д.42, к.1, "Ягодка Village"</p>
                        </div>
                        <div className="timeandplace--content--col">
                            <h5>Мы вам перезвоним!</h5>
                            <input type="tel" id="timeandplace--phone" placeholder="Ваш номер" /><br />
                            <button className="btn-small" onClick={this.sendCallbackForm}>Заказать обратный звонок</button>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}