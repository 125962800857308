import React from 'react';
import Slider from "react-slick";
import SVG from 'react-inlinesvg';
import waves from '../svg/waves.svg';
import {isMobile} from 'react-device-detect';

export default class BrandCloudSection extends React.Component {
    render() {
        return (
            <section className="brand-cloud jarallax">
                <picture className="jarallax-img">    
                    <img src="/images/parallax-brands.jpg" alt="" />
                </picture>
                <div className="parallax-blur" />
                <SVG src={waves} className="svg-divider reverse top"/>
                <div className="container brand-cloud--content">
                    <h3>Большой выбор сантехники в наличии и под заказ</h3>
                    <Slider
                        dots={false}
                        infinite={true}
                        speed={500}
                        slidesToShow={isMobile ? 3 : 5}
                        slidesToScroll={1}
                        autoplay={true}
                        autoplaySpeed={3000}
                        centerMode={false}
                        >
                        {[1,2,3,4,5,6,7].map(v => <img src={`/images/brands/${v}.png`} alt={`Бренд ${v}`} />)}
                    </Slider>
                    <div className="btn-wrapper">
                        <a className="btn" href="/catalog_latest.xls" target="_blank">Скачать каталог товаров (xls)</a>
                    </div>
                </div>
                <SVG src={waves} className="svg-divider red inverse" />
            </section>
        );
    }
}