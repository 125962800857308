import React from 'react';
import SVG from 'react-inlinesvg';
import waves from '../svg/waves.svg';

export default class DeliverySection extends React.Component {
    render() {
        return (
            <section className="delivery jarallax">
                <picture className="jarallax-img">    
                    <img src="/images/parallax-delivery.jpg" alt="" />
                </picture>
                <div className="parallax-blur" />
                <SVG src={waves} className="svg-divider reverse top"/>
                {/*eslint-disable-next-line*/}
                <a id="delivery" />
                <div className="container delivery--content">
                    <div className="delivery--content--row">
                        <div className="delivery--content--row--item">
                            <h3>Доставка</h3>
                            <p>
                                Товары представленные в нашем магазине можно посмотреть, выбрать и забрать самовывозом,
                                также возможна доставка нашими силами. <br /><br />
                                Подробности необходимо уточнить по телефонам:<br />
                                <strong>Торговая точка</strong> <a href="tel:+74993933037">+7 (499) 393-30-37</a> (Телефон, WhatsApp) <br />
                                <strong>Отдел снабжения</strong> <a href="tel:+79067737245">+7 (906) 773-72-45</a> (Телефон, WhatsApp, Viber, Telegram) <br />
                                Доставка осуществляется по договорённости.
                            </p>
                        </div>
                        <div className="delivery--content--row--item">
                            <h3>Оплата</h3>
                            <p>
                            В нашем магазине предусмотрены различные способы оплаты: <br /><br />
                            <strong>1. Наличный расчет</strong><br />
                            Клиенту выдается чек и расходная накладная с наименованиями и суммой приобретённого оборудования<br />
                            <br />
                            <strong>2. Безналичный расчёт с помощью терминала</strong><br />
                            Клиенту выдается чек и расходная накладная с наименованиями и суммой приобретённого оборудования<br />
                            <br />
                            <strong>3. Безналичный расчёт с помощью онлайн перевода на карту</strong> <br />
                            Клиенту выдается расходная накладная с наименованиями и суммой приобретённого оборудования<br />
                            <br />
                            <strong>4. Оплата по счёту в любом отделении банка, или перевод денежных средств со счёта организации (ООО, ОАО и т.д)</strong><br />
                            Клиенту выдается товарная накладная,счёт фактура а так же весь необходимый пакет документов с печатями наименованиями и суммой приобретённого оборудования<br />
                            <div className="center-align"><a href="/details.doc" className="btn-small">Реквизиты</a></div>
                            </p>
                        </div>
                    </div>
                </div>
                <SVG src={waves} className="svg-divider red"/>
            </section>
        );
    }
}