import React from 'react';
import {isMobile} from 'react-device-detect';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';


export default class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menu_open: false,
            modal_open: false,
        };
    }

    componentDidMount() {
        tippy('[data-tippy-content]');
    }

    toggleMenu = () => {
        if(!this.state.menu_open && this.state.modal_open) {
            this.setState({menu_open: true, modal_open: false});
        } else {
            this.setState({menu_open: !this.state.menu_open});
        }
        console.log("Is menu open?: ", this.state.menu_open);
    }

    toggleModal = () => {
        if(!this.state.modal_open && this.state.menu_open) {
            this.setState({menu_open: false, modal_open: true});
        } else {
            this.setState({modal_open: !this.state.modal_open});
        }
        console.log("Is modal open?: ", this.state.modal_open);
    }
    
    scrollToElem = (elem, pos="center") => {
        const el = document.getElementById(elem);
        console.log("Is mobile? :", isMobile);
        if(isMobile){
            this.toggleMenu();
        }
        console.log("Scrolling to ", el);
        if (el !== null) {
            el.scrollIntoView({block: pos, behavior: "smooth"});
        }
    }

    render() {
        return (
            <div id="navigation" className={this.state.menu_open || this.state.modal_open ? "navigation nontransparent" : "navigation"}>
                <div className="container navigation--container">
                    {/* eslint-disable-next-line */}
                    <a id="phone-toggle-mobile" onClick={this.toggleModal}><i className="fas fa-phone" /></a>
                    <div className="header-logo">
                        <a href="/"><img src={`/images/logo_${isMobile ? 'small': 'small'}.svg`} alt="Логотип"/></a>
                    </div>
                    {/* eslint-disable-next-line */}
                    <a id="nav-toggle-mobile" onClick={this.toggleMenu}><i className="fas fa-bars" /></a>
                    <nav className={this.state.menu_open ? "open" : ""}>
                        <li><button onClick={() => {this.scrollToElem('top')}}>Главная</button></li>
                        <li><button onClick={() => {this.scrollToElem('catalog', 'start')}}>Каталог товаров</button></li>
                        <li><button onClick={() => {this.scrollToElem('contact')}}>Обратная связь</button></li>
                        <li><button onClick={() => {this.scrollToElem('about', 'start')}}>О нас</button></li>
                        <li><button onClick={() => {this.scrollToElem('work')}}>Наши работы</button></li>
                        <li><button onClick={() => {this.scrollToElem('delivery', 'start')}}>Доставка и оплата</button></li>
                        <li><button onClick={() => {this.scrollToElem('footer', 'start')}}>Контакты</button></li>
                        <li><button onClick={() => {this.scrollToElem('team')}}>Наша команда</button></li>
                    </nav>
                    <div className={this.state.modal_open ? "header-contacts open" : "header-contacts"}>
                        <div className="header-contacts--phones">
                            <a href="tel:+74993933037">
                                <i className="fas fa-phone" data-tippy-content="Позвоните нам!"/>
                                <i className="fab fa-whatsapp" data-tippy-content="WhatsApp"/> 
                                <span data-tippy-content="Нажмите, чтобы позвонить"> +7 (499) 393-30-37</span>
                            </a>
                            <a href="tel:+79067737245">
                                <i className="fas fa-phone" data-tippy-content="Позвоните нам!"/> 
                                <i className="fab fa-whatsapp" data-tippy-content="WhatsApp"/>  
                                <i className="fab fa-viber" data-tippy-content="Viber"/> 
                                <span data-tippy-content="Нажмите, чтобы позвонить" >+7 (906) 773-72-45</span>
                            </a>
                        </div>
                        <div className="header-contacts--emails">
                            <a href="mailto:info@santidom.ru"><i className="fas fa-envelope" /> info@santidom.ru</a>
                        </div>
                        <div className="header-contacts--social">
                            <a href="https://vk.com/public191997077" rel="noopener noreferrer" target="_blank" data-tippy-content="Мы ВКонтакте"><i className="fab fa-vk"/></a>
                            <a href="https://www.instagram.com/santi_santehnika/?r=nametag" rel="noopener noreferrer" target="_blank" data-tippy-content="Instagram"><i className="fab fa-instagram"/></a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}